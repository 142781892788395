<template>
  <s-drawer
    ref="phoneDrawerRef"
    :visible="show"
    :size="'80rem'"
    :append-to-body="true"
    class="c-phone-drawer j-login-phone__code-list"
    @close-from-icon="close"
  >
    <template #title>
      {{ title }}
    </template>
    <div class="c-phone-drawer__body">
      <ul 
        class="newpop-select-area"
      >
        <!-- 区号列表 -->
        <li
          v-for="(item, index) in combineCountrys"
          :key="index"
          :class="[{active: item.abbr == abbr, unselectable: item.disabled, 'j-phone-title': item.disabled}]"
          @click="handleItemClick(item, index)"
        >
          <span class="phone-country">{{ `${item.name}${item.type == 'title' ? '': ` (+${item.code})` }` }}</span>
          <i
            v-if="item.abbr == abbr"
            class="suiiconfont sui_icon_selected_16px"
          ></i>
        </li>
      </ul>
      <!-- 右侧字母列表 -->
      <div 
        class="c-upper-silder"
        @touchstart.stop.prevent="onShortcutTouchStart"
        @touchend.stop.prevent="onShortcutTouchEnd"
        @touchmove.stop.prevent="onShortcutTouchMove"
      >
        <ul 
          ref="capitalListRef" 
          class="capital-list"
        >
          <li 
            v-for="(item, index) in formateCapitalList" 
            :key="index" 
            :ref="'j-shortcut-' + index"
            :data-index="index"
          >
            <span 
              :data-index="index" 
              class="shortcut-item"
            >{{ item }}</span>
          </li>
        </ul>
      </div>
      <div 
        v-show="touch.show"
        ref="shortcutSelected" 
        class="shortcut-selected"
      >
        <img 
          :src="PUBLIC_CDN + '/pwa_dist/images/combined_shape-6254232b71.png'" 
          alt="" 
        />
        <span class="name">{{ touch?.shortcutValue }}</span>
      </div>
    </div>
  </s-drawer>
</template>

<script>
import { SDrawer } from '@shein-aidc/sui-drawer/mobile'
import { defineComponent } from 'vue'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import UserInfoManager from 'public/src/services/UserInfoManager/UserInfoManager.js'
import { phoneCodeCache } from '../../util'
const { PUBLIC_CDN, language } = gbCommonInfo

export default defineComponent({
  name: 'PhoneAreaPop',
  components: {
    SDrawer,
  },
  props: {
    show: { type: Boolean, default: false },
    abbr: { type: String, default: '' },
    countryCodes: { type: Array, default: () => ([]) },
    title: { type: String, default: '' },
    phoneAreaCodePopop: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    PUBLIC_CDN,
    possibleCountries: [], // 推荐区号
    formateCountrys: [],
    capitalList: [],
    touch: {
      index: -1,
      show: false,
      shortcutValue: ''
    },
  }),
  computed: {
    combineCountrys() {
      return [...this.formatePossibleCountries, ...this.formateCountrys]
    },
    formateCapitalList() {
      if(this.formatePossibleCountries.length) {
        return ['✤', ...this.capitalList]
      }
      return this.capitalList
    },
    formatePossibleCountries() {
      let list = []
      if(this.possibleCountries.length > 0) {
        list = [{
          type: 'title',
          name: language?.SHEIN_KEY_PWA_26905,
          disabled: true,
        }, ...this.possibleCountries]
      }
      return list
    },
  },
  watch: {
    countryCodes: {
      handler: function (val){
        if(val) {
          this.handleFilterList()
        }
      },
      immediate: true
    },
    show(val) {
      if(val) {
        this.initData()
      }
    },
  },
  methods: {
    initData(){
      const cachePhoneCode = phoneCodeCache.getValue(0)
      const { countryAbbr: ipCode } = UserInfoManager.getSync({ key: 'Country', actionType: 'login/getCountryCode' }) || {}
      const defaultLocation = JSON.parse(localStorage.getItem('LOCATION_CURRENT')) || {}
      const localCode = defaultLocation?.value || ''
      const siteUidCodes = JSON.parse(localStorage.getItem('SITEUID_COUNTRYS')) || []
      let formateCodes = [];
      [cachePhoneCode, ipCode, localCode].map(el => {
        if(el) {
          formateCodes.push(el)
        }
      })
      formateCodes.push(...siteUidCodes)
      // 去重
      formateCodes = Array.from(new Set(formateCodes))
      // 按照formateCodes的顺序在countrys寻找对象，组合生成新的对象数组
      const formateCountrys = this.countryCodes.filter(country => formateCodes.includes(country.value)).sort((a, b) => {
        const aIndex = formateCodes.indexOf(a.value)
        const bIndex = formateCodes.indexOf(b.value)
        return aIndex - bIndex
      })
      // 取前五个
      this.possibleCountries = formateCountrys.splice(0, 5)
      daEventCenter.triggerNotice({
        daId: '2-8-72',
        extraData: {
          is_common_areacode: 1,
        },
      })
    },
    handleFilterList() {
      const arr = this.countryCodes.concat([])
      const list = []
      for (let i = 0, len = arr.length; i < len; i++) {
        //查询字母异同，异则插入当前位置
        if (arr[i]?.name?.charAt(0) !== arr[i - 1]?.name?.charAt(0)) {
          list.push(arr[i].name.charAt(0))
          arr.splice(i, 0, {
            type: 'title',
            name: arr[i].name.charAt(0),
            disabled: true,
          })
          i++
          len++
        }
      }
      this.formateCountrys = arr
      this.capitalList = list
    },
    onShortcutTouchStart (e) {
      const index = e.target.getAttribute('data-index')
      if(!index) {
        return
      }
      this.touch = {
        index: index,
        show: true,
        shortcutValue: this.formateCapitalList[index]
      }
      this._scrollTo(index)
    },
    onShortcutTouchMove (event) {
      const touchX = event.touches[0].clientX
      const touchY = event.touches[0].clientY
      const element = document.elementFromPoint(touchX, touchY)
      if(!element) {
        return
      }
      const currentIndex = element.getAttribute('data-index')
      if(!currentIndex) {
        return
      }
      if(currentIndex != this.touch.index) {
        this.touch = {
          index: currentIndex,
          show: true,
          shortcutValue: this.formateCapitalList[currentIndex]
        }
        this._scrollTo(currentIndex)
      }
    },
    onShortcutTouchEnd () {
      this.touch.show = false
    },
    _scrollTo (index) {
      // 右侧选中提示
      try { // 防止获取不到dom
        const phoneDrawerEl = document.querySelector('.j-login-phone__code-list')
        const capitalQueryDom = phoneDrawerEl.querySelector('.sui-drawer__container')
        const capitalStart = capitalQueryDom.getBoundingClientRect().top
        const oShortcutItem = this.$refs['j-shortcut-' + index][0]
        const capitalTop = oShortcutItem.getBoundingClientRect().top
        this.$refs.shortcutSelected.style.top = capitalTop - capitalStart - 3 + 'px'
        
        // 左侧列表
        const countryQueryDom = phoneDrawerEl.querySelector('.sui-drawer__body')
        const countryStart = countryQueryDom.getBoundingClientRect().top
        const oCountryTitle = phoneDrawerEl.querySelectorAll('.j-phone-title')[index]
        const countryTop = oCountryTitle.getBoundingClientRect().top
        countryQueryDom.scrollTop = countryTop - countryStart +  countryQueryDom.scrollTop
      } catch (error) {
        console.log(error)
      }
    },
    close () {
      this.$emit('close')
    },
    handleItemClick (item, index) {
      if(item.type == 'title') {
        return
      }
      phoneCodeCache.set(item.abbr, 0)
      this.$emit('select-item', item)
      const flag = this.formatePossibleCountries.length && index < this.formatePossibleCountries.length && this.phoneAreaCodePopop
      daEventCenter.triggerNotice({ daId: '2-8-71', extraData: { 
        result: item.abbr == this.abbr ? 'not_change' : 'change',
        is_common_areacode: flag ? 1 : 0,
      } })
    }
  }
})
</script>

<style lang="less" scoped>
.c-phone-drawer {
  .newpop-select-area{
    flex-grow: 1;
    overflow: auto;
    li {
      padding: 0.32rem .8rem 0.32rem 0.32rem;
      font-size: 14px;
      color: #222;
      display: flex;
      align-items: center;
      justify-content: space-between;
      [class*='suiiconfont'] {
        font-size: 20px;
        color: @sui_color_main;
        padding-right: .533rem;
      }
    }
    li.active {
      font-weight: bold;
      color: @sui_color_main;
    }
    li.unselectable {
      cursor: auto;
      padding: 0.32rem 0.8rem 0.1rem 0.32rem;
      font-weight: bold;
      color: @sui_color_main;
    }
  }

  .shortcut-selected {
    position: fixed;
    .font-dpr(40px);
    color: #fff;
    .right(.9rem);
    width: .90666rem;
    height: .64rem;
    line-height: .64rem;
    .name {
      position: relative;
      .padding-l(.16rem);
    }
    img {
      position: absolute;
      width: 100%;
    }
  }
}
.c-upper-silder {
  position: absolute;
  top: 54%;
  .right(0);
  z-index: 2999; /* stylelint-disable-line declaration-property-value-blacklist */
  transform: translate3d(0,-50%,2999px);
  overflow-y: hidden;
  text-align: center;
  font-size: 11px;
}
.capital-list {
  li {
    width: .8rem;
    height: .38rem;
    margin-bottom: 5px;
    font-size: 12px;
    color: @sui_color_gray_dark2;
    text-align: center;
    .shortcut-item {
      display: inline-block;
      &.selected {
        background: #222;
        border-radius: 50%;
        color: #fff;
      }
    }
  }
}
// 解决Safari浏览器兼容性问题
.phone-country:not(:empty):after {
    content: "i";
    visibility: hidden;
    font-size: 0;
}
.phone-country:not(:empty):before {
    content: "i";
    visibility: hidden;
    font-size: 0;
}

</style>
